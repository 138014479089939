.fc-list-event-graphic,
.fc-daygrid-day-bg,
.fc-daygrid-day-events {
  display: none;
}

.fc .fc-timegrid-axis-cushion, 
.fc .fc-timegrid-slot-label-cushion, 
.fc .fc-col-header-cell-cushion {
  font-family: "AvenirNext", "Avenir", "Roboto", sans-serif;
    font-weight: normal;
}

.fc .fc-list-table th {
  z-index: 1;
}

.fc .fc-popover {
  z-index: 10;
}

.fc-col-header-cell {
  color: rgba(0, 0, 0, 0.54);
  font-weight: normal;
}

.fc-list-event-time {
  text-align: center;
}

.fc-daygrid-day-number {
  padding: 0 !important;
  width: 100%;
}

.fc-list-event-title {
  padding-left: 0 !important;
}

.fc .fc-list-event:hover td {
  background: none;
}

.fc-day-grid-event > .fc-content {
  white-space: normal;
  text-overflow: ellipsis;
  max-height:20px;
}
.fc-day-grid-event > .fc-content:hover {
  max-height:none!important; 
}

.MuiOutlinedInput-adornedStart {
  padding: 0;
}
.fc-popover {
  top: 30px !important;
}
.fc-more-popover {
  height: 85%;
  max-height: 95%;
  overflow-y: auto;
}
.MuiDialog-paperWidthMd{
  height: 100%;
}